/* responsive tweaks
---------------------------*/
@media (max-width: 992px) {
  .col-md-margin {
    margin-top: 20px;
  }
}
#buttonsView #info-panel {
  margin-top: 40px;
  margin-bottom: 40px;
}
#buttonsView #info-panel p {
  margin-top: 40px;
}
/* Link colors
--------------------------------*/
a.disabled,
a.disabled:hover,
a.disabled:visited {
  color: #333;
  text-decoration: none;
  cursor: default;
}
#topicView a,
#wrap #topicView .container a,
#topicView a:visited,
#wrap #topicView .container a:visited {
  color: #333;
}
#regionView a,
#wrap #regionView .container a,
#regionView a:visited,
#wrap #regionView .container a:visited {
  color: #333;
}
#regionView .description a,
#wrap #regionView .container .description a,
#regionView .description a:visited,
#wrap #regionView .container .description a:visited {
  color: #337ab7;
}
.atlas-loading {
  text-align: center;
  width: 100%;
}
.atlas-loading img {
  margin-top: 40px;
}
.stats_label,
.col-md-4.stats_label {
  padding-left: 0;
}
#tooltip {
  position: absolute;
  height: auto;
  padding: 4px 8px;
  background-color: #000000;
  color: #efefef;
  z-index: 10000;
  display: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  opacity: 0.5;
  font: "arial";
}
#tooltip .poi_info,
#tooltip span {
  font-size: 12px;
}
#tooltip span {
  color: white;
  opacity: 1.0 !important;
}
#legend {
  margin-top: 20px;
  font-size: 12px;
}
#legend .colors {
  border: 1px solid #CCC;
  height: 20px;
}
#legend .legenditem {
  display: inline-block;
  height: 12px;
}
.atlas-footer img.img-responsive {
  background: white;
  padding: 5px;
  border-radius: 2px;
}
.coatsofarms {
  filter: gray;
  /* IE6-9 */
  filter: grayscale(1);
  /* Microsoft Edge and Firefox 35+ */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
}
.coatsofarms:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  text-decoration: none;
}
#coats {
  margin-top: 10px;
}
.mapinfo {
  margin-left: 10px;
  margin-top: 5px;
  float: right;
  text-align: center;
  background-color: #00F;
  color: #FFF;
  font-size: 14px;
  border-radius: 30px;
  z-index: 50000;
  border: 10px solid #ddd;
}
div.disabled {
  opacity: .5;
}
nav .pagination li a {
  color: black;
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
/***
Scrollable bootstrap dropdown
http://stackoverflow.com/questions/19227496/scrollable-menu-with-bootstrap-3-menu-expanding-its-container-when-it-should-n
****/
.scrollable-menu {
  height: auto;
  max-height: 400px;
  overflow-x: hidden;
}
.scrollable-menu::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 12px;
}
.scrollable-menu::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: lightgray;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.75);
}
select.clean {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
.atlas-footer {
  padding-top: 20px;
  padding-bottom: 50px;
  text-align: left;
  border-top: 1px solid #e7e7e7;
}
.atlas-footer ul li {
  list-style-type: none;
}
.container-step {
  margin-top: 60px;
  margin-bottom: 60px;
}
.colorpicker {
  width: 100%;
  background-color: white;
}
.colorpicker .colorbar {
  width: 100%;
  display: table;
}
.colorpicker .colorbar .colorbar-item {
  display: table-cell;
}
.colorpicker .colorbar .colorbar-colors {
  display: table-cell;
  width: 60%;
}
.colorpanel {
  padding: 4px;
  margin-top: 2px;
  margin-right: 2px;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid white;
}
.colorpickerColors {
  position: absolute;
  z-index: 1000;
  overflow: auto;
  padding: 5px;
  box-shadow: 5px 5px 5px #99979c;
  background-color: #FFF;
  border: 1px solid #CCC;
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 20px;
}
.colorpanel:hover {
  border: 1px solid #CCC;
}
.colorpicker_colorbag {
  display: inline-block;
  height: 12px;
}
#wrap #topic-badge,
#wrap #region-badge,
#wrap #buttons-badge {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #DEEBF7;
  /* fallback color if gradients are not supported */
  background-image: -webkit-linear-gradient(to bottom right, #DEEBF7, white);
  background-image: -o-linear-gradient(to bottom right, #DEEBF7, white);
  background-image: linear-gradient(to bottom right, #DEEBF7, white);
}
.topic-container {
  margin-bottom: 30px;
}
.chart_caption {
  text-align: 'center';
  font-size: 12px;
  margin-top: 10px;
}
h3 select.form-control {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
  padding: 0px;
  box-shadow: none;
  color: #333333;
  border: 1px solid white;
}
select.form-control:hover {
  border: 1px solid #ccc !important;
}
select.bilo-inline-control {
  padding: 0px;
  box-shadow: none;
  color: black;
  font-size: 85%;
  height: 20px;
  border: 1px solid white;
}
a {
  cursor: pointer;
}
h2 a:hover,
#coats a:hover {
  text-decoration: none;
}
/*
Topic selection
----------------------------------
*/
.topic-view-navigation {
  margin-bottom: 10px;
}
/*
    Additional layer
    -----------------------------------------------------
*/
#addition_layer {
  margin-top: 5px;
  padding: 10px;
}
#addition_layer .btn-remove {
  margin-top: 10px;
}
#addition_layer h1 {
  /*display: none;*/
}
#addition_layer h3 select.form-control {
  font-size: 12px;
}
.container_additional_layer {
  margin-top: 20px;
}
/* Sticky footer styles
-------------------------------------------------- */
html,
body {
  height: 100%;
  /* The html and body elements cannot have any padding or margin. */
}
#wrap {
  min-height: 100%;
  height: auto !important;
  /* This line and the next line are not necessary unless you need IE6 support */
  height: 100%;
  margin: 0 auto -120px;
  /* the bottom margin is the negative value of the footer's height */
}
/* Set the fixed height of the footer here */
#push,
#footer {
  height: 120px;
}
/* Lastly, apply responsive CSS fixes as necessary */
@media (max-width: 767px) {
  #footer {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */
#wrap > .container {
  padding-top: 60px;
}
/* Custom Bootstrap Theme
-------------------------------------------------- */
#wrap .container a,
#wrap .container a:visited {
  color: #214E5E;
}
#wrap .jumbotron {
  background-color: #DEEBF7;
  color: #272B5F;
  border-radius: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}
#wrap .jumbotron h1 {
  color: #272B5F;
  text-align: left;
  font-size: 48px;
}
#wrap .jumbotron h1.text-center {
  text-align: center;
}
#wrap .jumbotron h1 small {
  color: lightgrey;
}
#wrap .dropdown-header {
  text-align: center;
}
#wrap .label-secondary {
  background-color: #AAA;
  margin-left: 5px;
}
#wrap .years .year span.label {
  width: 50px;
  display: inline-block;
}
#topicView .well,
#regionView .well {
  background-image: -webkit-linear-gradient(top, #DEEBF7 0, #F7FBFF 100%);
  background-image: -o-linear-gradient(top, #DEEBF7 0, #F7FBFF 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#DEEBF7), to(#F7FBFF));
  background-image: linear-gradient(to bottom, #DEEBF7 0, #F7FBFF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bgcolor_dark', endColorstr='@bgcolor_light', GradientType=0);
  background-color: #F7FBFF;
  border-radius: 0px;
  border: 1px solid #DEEBF7;
}
#topicView .alert,
#regionView .alert {
  margin-top: 10px;
}
#topicView .btn-default,
#regionView .btn-default {
  background-image: none;
}
/* Ring component
-------------------------------------------------- */
#topic-ring path.slice {
  stroke-width: 1px;
}
#topic-ring line {
  opacity: .3;
  stroke: black;
  stroke-width: 1px;
  fill: none;
  stroke-dasharray: 2 2;
}
#topic-ring polyline {
  opacity: .3;
  stroke: black;
  stroke-width: 2px;
  fill: none;
}
#topic-ring text {
  font-size: 11px;
  width: 120px;
}
#topic-ring .value-label {
  fill: #99979d;
}
#topic-ring .nodata {
  transform: translate(100000px, 100000px);
}
/* Pois
-------------------------------------------------- */
.poi {
  font-size: 12px;
}
#legend .legend-item {
  font-size: 12px;
  cursor: pointer;
}
#legend .legend-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
#legend ul {
  -webkit-padding-start: 0px;
  padding-left: 0px;
}
#legend ul li {
  list-style: none;
}
#legend ul li b {
  font-weight: normal;
  margin-bottom: 5px;
  display: block;
  margin-top: 20px;
}
/* Region-map
-------------------------------------------------- */
#buttonsView #regions-map path {
  stroke: #FFF;
}
#buttonsView #regions-map path:hover {
  stroke: #666;
}
/* Region-map
-------------------------------------------------- */
#regions-map path {
  stroke: #AAA;
}
#regions-map path:hover {
  stroke: #666;
}
/* Region view
-------------------------------------------------- */
#regionView #ofr-map path,
#locationView #ofr-map path,
#buttonsView #ofr-map path {
  stroke: #FFF;
}
#regionView #ofr-map path:hover,
#locationView #ofr-map path:hover,
#buttonsView #ofr-map path:hover {
  stroke: #666;
}
#regionView .well,
#locationView .well,
#buttonsView .well {
  background-image: -webkit-linear-gradient(top, #DEEBF7 0, #F7FBFF 100%);
  background-image: -o-linear-gradient(top, #DEEBF7 0, #F7FBFF 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#DEEBF7), to(#F7FBFF));
  background-image: linear-gradient(to bottom, #DEEBF7 0, #F7FBFF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bgcolor_dark', endColorstr='@bgcolor_light', GradientType=0);
  background-color: #F7FBFF;
  border-radius: 0;
  border: 1px solid #DEEBF7;
  box-shadow: none;
}
#regionView .well .alert,
#locationView .well .alert,
#buttonsView .well .alert {
  margin-bottom: 0px;
}
#regionView h4,
#locationView h4,
#buttonsView h4 {
  border-bottom: 1px solid #AAA;
  padding-bottom: 3px;
  color: #AAA;
  font-weight: 100;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 20px;
}
#regionView .calculation_label,
#locationView .calculation_label,
#buttonsView .calculation_label {
  line-height: 13px;
  padding-top: 4px;
}
@media (min-width: 767px) {
  #regionView .calculation_value,
  #locationView .calculation_value,
  #buttonsView .calculation_value {
    margin-top: 14px;
  }
}
/* Region view
-------------------------------------------------- */
#regionView .nvd3 g.nv-linesWrap,
#regionView .nvd3 g.nv-scatterWrap {
  transform: translateX(2px);
}
#regionView .nvd3 .nv-axis .zero line,
#regionView .nvd3 .nvd3 .nv-axis line.zero {
  stroke: darkgrey;
  stroke-width: 1;
}
/*---------------------------------
     CSS for Admin section
-----------------------------------*/
.text-deleted {
  text-decoration: line-through;
}
/*  Value table */
#grid_values {
  width: 100%;
  height: 600px;
}
